module.exports={
  "name": "biggie",
  "version": "1.1.2",
  "description": "minimalist javascript frontend framework with bigwheel",
  "main": "gulpfile.js",
  "browser": {
    "framework": "./assets/js/framework",
    "config": "./assets/js/config",
    "cache": "./assets/js/cache",
    "utils": "./assets/js/utils",
    "@utils/array": "./assets/js/utils/array",
    "@utils/biggie": "./assets/js/utils/biggie",
    "@utils/css": "./assets/js/utils/css",
    "@utils/dom": "./assets/js/utils/dom",
    "@utils/func": "./assets/js/utils/func",
    "@utils/math": "./assets/js/utils/math"
  },
  "dependencies": {
    "bigwheel": "3.0.0",
    "bw-router": "^2.0.6",
    "bw-viewmediator": "2.1.0",
    "bw-vm": "^2.1.3",
    "dom-classes": "^1.0.0",
    "dom-create-element": "^1.0.2",
    "dom-event": "^1.0.0",
    "dom-select": "^1.0.0",
    "gsap": "^1.19.0",
    "please-ajax": "^2.0.2",
    "prefix": "^1.0.0",
    "query-dom-components": "^0.1.0",
    "sniffer": "github:watsondg/sniffer"
  },
  "devDependencies": {
    "babel-preset-es2015": "^6.16.0",
    "babelify": "^7.2.0",
    "browser-sync": "^2.18.5",
    "browserify": "^13.3.0",
    "connect-history-api-fallback": "^1.3.0",
    "es6-promise": "^4.0.5",
    "gulp": "^3.8.11",
    "gulp-autoprefixer": "^3.1.1",
    "gulp-changed": "^1.3.2",
    "gulp-clean-css": "^2.3.2",
    "gulp-concat": "^2.6.1",
    "gulp-jshint": "^2.0.3",
    "gulp-less": "^3.3.0",
    "gulp-notify": "^2.0.1",
    "gulp-rename": "^1.2.2",
    "gulp-sourcemaps": "^2.2.1",
    "gulp-uglify": "^2.0.0",
    "gulp-util": "^3.0.8",
    "jshint": "^2.9.4",
    "lodash.assign": "^4.2.0",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0",
    "vinyl-transform": "^1.0.0",
    "watchify": "^3.6.1"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/baptistebriel/biggie.git"
  },
  "bugs": {
    "url": "https://github.com/baptistebriel/biggie/issues",
    "email": "baptiste@sa-studio.fr"
  },
  "browserify": {
    "transform": [
      [
        "babelify",
        {
          "only": "assets/js/",
          "presets": [
            "es2015"
          ]
        }
      ]
    ]
  },
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "author": "Baptiste Briel",
  "license": "ISC"
}
