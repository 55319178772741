import config from 'config'

/* ----------
all routes needs to be defined inline
see https://github.com/bigwheel-framework/documentation/blob/master/routes-defining.md#as-section-standard-form
---------- */
module.exports = {
	[`${config.BASE}`]: require('./sections/chanels'),
	[`${config.BASE}home`]: { section: require('./sections/chanels') },
	[`${config.BASE}tag`]: { section: require('./sections/chanels') },
	[`${config.BASE}prelaunch`]: { section: require('./sections/chanels') },
	[`${config.BASE}preview`]: { section: require('./sections/chanels') },
	[`${config.BASE}about`]: { section: require('./sections/chanels') },
	[`${config.BASE}shop`]: { section: require('./sections/chanels') },
	[`${config.BASE}contribute`]: { section: require('./sections/chanels') },
	[`${config.BASE}contribute-thanks`]: { section: require('./sections/chanels') },
	[`${config.BASE}stories/:id`]: { section: require('./sections/chanels'), duplicate: true },
	[`${config.BASE}stories/:category/:id`]: { section: require('./sections/chanels'), duplicate: true },
	[`${config.BASE}issues/:id`]: { section: require('./sections/chanels'), duplicate: true },
	[`${config.BASE}preview/:id`]: { section: require('./sections/chanels'), duplicate: true }
}