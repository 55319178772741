import config from 'config'
import sniffer from 'sniffer'
import classes from 'dom-classes'
import create from 'dom-create-element'
import gsap from 'gsap'
import domselect from 'dom-select'

TweenLite.defaultEase = Expo.easeOut

class Preloader {
	
	constructor(onComplete) {
		
		this.preloaded = onComplete
		this.view = config.view
		this.el = null
	}
	
	init(req, done) {

		classes.add(config.body, 'is-loading')

		config.infos = sniffer.getInfos()
        
		this.createDOM()

		done()
	}
	
	createDOM() {
		
		const page = this.view.firstChild
		
		this.el = create({
			selector: 'div',
			styles: 'preloader',
			html: `
				<div class="vertical-align">
					<div class="vertical-align__item">
						<p>S Magazine</p>
					</div>
				</div>
			`
		})

		this.view.insertBefore(this.el, page)

		var menu = domselect('.mobile-menu'),
			menuTrigger = domselect('.menuTrigger'),
			menuItem = domselect.all('a.nav-item'),
			closeMenu = domselect('.closeMenu');

			menuTrigger.addEventListener('click', function(){
				menu.classList.add('_on')
			});
			closeMenu.addEventListener('click', function(){
				menu.classList.remove('_on')
			});
			[].forEach.call(menuItem, function(els){
				els.addEventListener('click', function(){
					menu.classList.remove('_on')
				});
			})

		//remove hover on mobile//
		var touch = 'ontouchstart' in document.documentElement
            || navigator.maxTouchPoints > 0
            || navigator.msMaxTouchPoints > 0;

		if (touch) { // remove all :hover stylesheets
		    try { // prevent exception on browsers not supporting DOM styleSheets properly
		        for (var si in document.styleSheets) {
		            var styleSheet = document.styleSheets[si];
		            if (!styleSheet.rules) continue;

		            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
		                if (!styleSheet.rules[ri].selectorText) continue;

		                if (styleSheet.rules[ri].selectorText.match(':hover')) {
		                    styleSheet.deleteRule(ri);
		                }
		            }
		        }
		    } catch (ex) {}
		}
	}

	resize(width, height) {

		config.width = width
		config.height = height
	}

	animateIn(req, done) {

		const tl = new TimelineMax({ paused: true, onComplete: () => {
			done()
			// call this.preloaded to bring the first route
			this.preloaded()
		}})
		tl.to(this.el, 1, {autoAlpha: 1})
		tl.restart()
	}
	
	animateOut(req, done) {

		const tl = new TimelineMax({ paused: true, onComplete: done })
		tl.to(this.el, 1, {autoAlpha: 0})
		tl.restart()
	}

	destroy(req, done) {

		classes.add(config.body, 'is-loaded')
		classes.remove(config.body, 'is-loading')

		this.view.removeChild(this.el)

		done()
	}
}

module.exports = Preloader