import config from 'config'
import utils from 'utils'
import classes from 'dom-classes'
import Default from './default'
import domselect from 'dom-select'
import Swiper from './swiper'
import PhotoSwipe from 'photoswipe/dist/photoswipe.js'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js' 
import ajax from 'please-ajax'

class Chanels extends Default {
	
	constructor(opt) {
		
		super(opt)

		this.slug = 'home'
		this.ui = null
	}
	
	init(req, done) {

		super.init(req, done)
	}

	ready(done) {

		super.ready()

		done()
	}

	animateIn(req, done) {

		var pager = document.querySelector('.pager'),
			pagerText = document.querySelector('.pager span p');
		var tile = document.querySelector('.meta-tile'),
	    	currentPage = document.querySelector('.js-current-page'),
	        s = tile.dataset.slug,
	        t = tile.dataset.title;
	        pager.classList.add('_hidden')

	        
			// setTimeout(function(){
			// 	//
			// 	pagerText.innerHTML = t;
				
			// }, 500)
			// setTimeout(function(){
			// 	//
			// 	pager.classList.add('_top')
				
			// }, 1000)
			// setTimeout(function(){
			// 	//
			// 	pager.classList.add('_hidden')
				
			// }, 1500)
			// setTimeout(function(){
			// 	pager.classList.remove('_top')
			// 	pager.classList.add('_bottom')
			// }, 2000)

		//classes.add(config.body, `is-${this.slug}`)
		//PAGE TITLES
	   
	        if(s === 'stories'){
	        	var cat = tile.dataset.cat;
	        	currentPage.innerHTML += ''
	        	currentPage.innerHTML += cat
	        }else{
	        	currentPage.innerHTML += ''
	        	currentPage.innerHTML += s
	        }
	        
	        classes.add(config.body, 'is-'+s)
	        classes.add(this.page, 'page-'+s)

	        if(domselect('#page-stories-fashion')){
	        	classes.add(config.body, 'is-fashion')
	        }else{
	        	classes.remove(config.body, 'is-fashion')
	        }

	        if(domselect('#page-stories-beauty')){
	        	classes.add(config.body, 'is-beauty')
	        }else{
	        	classes.remove(config.body, 'is-beauty')
	        }

	        if(domselect('#page-stories-culture')){
	        	classes.add(config.body, 'is-culture')
	        }else{
	        	classes.remove(config.body, 'is-culture')
	        }

		TweenLite.to(this.page, .7, {
			autoAlpha: 1,
			delay: -0.2,
			opacity:1,
			ease: Expo.easeInOut,
			onComplete: done
		})

		if(domselect.all('.videoWrapper')){
			// //YOUTUBE
	    var videoWrapper = domselect.all('.videoWrapper'),
			poster = domselect.all('.videoposter'),
			introArticle = domselect('.intro-article');

			[].forEach.call(videoWrapper, function(els){
				els.addEventListener('click', function(ev){
					var _vid = this.querySelector('iframe'),
						source = _vid.getAttribute('data-src');
					ev.preventDefault();
					this.classList.add('on');
					_vid.src = source + "?version=3&ampplayerapi&ampid=ytplayer&ampenablejsapi=1?rel=0&amp;showinfo=0&amp;autoplay=1&modestbranding=1";
					// console.log(playerapiid)
					// function onPlayerStateChange(event) {        
				 //        if(event.data === 0) {            
				 //            alert('done');
				 //        }
				 //    }

					focus();
					var listener = addEventListener('blur', function() {
						if(document.activeElement === _vid) {
							_vid.src='';
							[].forEach.call(videoWrapper, function(els){
								els.classList.remove('on');
								if(_vid.classList.contains('topVideo')){
									introArticle.classList.remove('_top');
								}
							});
						}
					    removeEventListener(listener);
					});


					if(_vid.classList.contains('topVideo')){
						introArticle.classList.add('_top');
					}
				
						
				})
			});
		}

		var conTrigger = domselect.all('.conTrigger'),
			thisIssue = domselect('.thisIssue'),
			contributerModalclose = domselect('.contributerModal .close'),
			contributerModalcontent = domselect('.contributerModalcontent'),
			contributerModal = domselect('.contributerModal');

			[].forEach.call(conTrigger, function(els){
				els.addEventListener("click", function(){
					thisIssue.innerHTML = this.getAttribute('data-title')
					contributerModal.classList.add('_on');
					contributerModalcontent.innerHTML = this.querySelector('.list').innerHTML
				})
				contributerModalclose.addEventListener("click", function(){
					contributerModal.classList.remove('_on');
					contributerModalcontent.innerHTML = ''
					thisIssue.innerHTML = ''
				})
			})

		if(tile.classList.contains('is-scroll')){

			   	// if (!this.hasNext()) {
		      //           loadButton.setAttribute('disabled', 'disabled');
		      //       }
		      	window.requestAnimFrame = (function(){
				  	return  window.requestAnimationFrame   ||
						window.webkitRequestAnimationFrame ||
						window.mozRequestAnimationFrame    ||
						window.oRequestAnimationFrame      ||
						window.msRequestAnimationFrame     ||
						function(/* function */ callback, /* DOMElement */ element){
						window.setTimeout(callback, 1000 / 60);
						};
				})();

				var Q = {},
				    scrollTarget = 0 ;

				var container = domselect('.page'),
					_cover = domselect.all('.cover'),
					_blocks = document.querySelectorAll('.vsDiv'), i,
					_columns = domselect.all('._offset'),
				    bottom = - (container.scrollHeight - container.scrollTop) + (window.innerHeight + 22);
				    
					Q.Y = 0;

					function render() {
				            
			            //console.log(Q.Y)

			            if( bottom > Q.Y){ Q.Y = bottom }
			            if (Q.Y > 0) { Q.Y = 0 }

			            if(scrollTarget > 50){
			                TweenLite.to('header', 1, {opacity: 0})
			            }else{
			                TweenLite.to('header', 1, {opacity: 1})
			            }
			            if(scrollTarget < -50){
			                TweenLite.to('header', 1, {opacity: 0})
			            }else{
			                TweenLite.to('header', 1, {opacity: 1})
			            }

			     		var nav = domselect('header nav'),
			     			footer = domselect('.footer');


			            TweenMax.to(_blocks, 0.25, {
			                y: Q.Y ,
			                ease: Expo.easeOut,
			                overwrite: 'auto'
			            });
			            [].forEach.call(_cover, function(els){

				            TweenMax.to(els, 0.5, {
				                y: - Q.Y/2,
				                ease: Expo.easeOut,
				                overwrite: 'auto'
				            });
			            })
			            //if(_columns){
							for (var i = 0; i < _columns.length; i++) {
					            TweenMax.to(_columns[i], 0.25 + ((i + 1) * 0.25), {
					                y: Q.Y,
					                ease: Expo.easeOut,
					                overwrite: 'auto'
					            });
					        }
					    //}

			        }

			        if (window.innerWidth < 1024) {
						container.addEventListener('scroll', function (event) {
					    	event.preventDefault()
					    	scrollTarget = container.pageXOffset || container.scrollTop
					  	})
					} else {
						window.addEventListener('wheel', function (event) {
					    	event.preventDefault()
					    	Q.Y -= event.deltaY
					  	})

					}
					function update(){
				    render();
				    window.requestAnimFrame(update);
				}

				update();

				if (s === 'home') {
					var loadMore = document.querySelector('.more');

					if (loadMore) {
						loadMore.addEventListener('click', function (e) {
							var page = loadMore.dataset.next;

							ajax.get('/load-more?page=' + page, {
								success: function success(object) {
									document.querySelector('.left-column').insertAdjacentHTML('beforeend', object.data.left);
									document.querySelector('.center-column').insertAdjacentHTML('beforeend', object.data.center);
									document.querySelector('.right-column').insertAdjacentHTML('beforeend', object.data.right);
									if (window.innerWidth > 1024) {
					             	 	//Q.Y -= 5000
					             	 	update();
					             	 	bottom = - (container.scrollHeight - container.scrollTop) + (window.innerHeight + 22);
						             	
						            }
								}
							});

							
						});
					}
				}

				console.log(document.querySelector(".device-content").offsetHeight)
				if(s === "contribute" && domselect('.creditTrigger')){
					var code = '<div class="creditGroup">'+
		                '<input type="text" name="credits" placeholder="Made Teglers" class="_credit">'+
		                '<select name="function" id="">'+
		                  '<option value="photographer" default>Photographer</option>'+
		                  '<option value="director" default>Director</option>'+
		                  '<option value="model" default>model</option>'+
		                  '<option value="stylist" default>Stylist</option>'+
		                  '<option value="makeup" default>Make-up</option>'+
		                  '<option value="clothes" default>Clothes by</option>'+
		                  '<option value="thanks" default>Thanks to</option>'+
		                '</select>'+
		              '</div>';
		             var creditTrigger = domselect('.creditTrigger'),
		             	 creditWrapper = domselect('#creditWrapper');

		             	 creditTrigger.addEventListener('click', function(){
		             	 	creditWrapper.insertAdjacentHTML('beforeend', code);
		             	 	if (window.innerWidth > 1024) {
			             	 	Q.Y -= 100
			             	 	bottom = - (container.scrollHeight - container.scrollTop) + (window.innerHeight + 22);
				             	update();
				             }
		             	 })
				}

		}
		if(domselect.all('a.more')){
			var aMore = domselect.all('a.more');
			[].forEach.call(aMore, function(els){
				els.addEventListener('click', function(){
					window.location.replace(this.getAttribute('href'));
				})
			})
		}
		var innerTrigger = domselect.all('.innerTrigger');

		[].forEach.call(innerTrigger, function(els){
			els.addEventListener('click', function(){
				var nextItem = this.nextSibling.nextElementSibling
				this.parentElement.classList.toggle('_on');
				this.classList.toggle('_on');
				nextItem.classList.toggle('_on');
				//console.log(nextItem)

			})
		})
		var top_swiper = domselect('.swiper-container._main'),
			galleryThumbs = domselect('.swiper-container.gallery-thumbs');
	
		// if(top_swiper){
		// 	var top_swiper = new Swiper('.swiper-container._main', {
		// 		slidesPerView: 'auto',
		// 		centeredSlides : true,
		// 		mousewheelControl: true,
		// 		direction: 'horizontal',
		// 		nextButton: '.swiper-button-next',
		// 		prevButton: '.swiper-button-prev',
		// 		loop: false,
		// 		speed: 900,
		// 		parallax: true,
		// 		mousewheelInvert:true,
		// 		paginationClickable: true,
		// 		keyboardControl: true,
		// 		a11y: true
	 //        });

	 //    }
	    if(galleryThumbs && top_swiper){
	    	var top_swiper = new Swiper('.swiper-container._main', {
				slidesPerView: 'auto',
				centeredSlides : true,
				mousewheelControl: true,
				direction: 'horizontal',
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				loop: false,
				speed: 900,
				parallax: true,
				mousewheelInvert:true,
				paginationClickable: true,
				keyboardControl: true,
				a11y: true
	        });

	        var galleryThumbs = new Swiper('.gallery-thumbs', {
		        spaceBetween: 10,
		        centeredSlides: true,
		        slidesPerView: 'auto',
		        touchRatio: 0.5,
		        slideToClickedSlide: true,
		        a11y: true
		    });
		    top_swiper.params.control = galleryThumbs;
		    galleryThumbs.params.control = top_swiper;

		    var _pagination = domselect('._pagination'),
				_current = domselect('._current'),	
				_total = domselect('._total'),
				_totalumber = domselect.all('.swiper-container._main .swiper-slide').length;

				
				_total.innerHTML = _totalumber ;
				_current.innerHTML = (top_swiper.activeIndex + 1) ;
				top_swiper.on('onSlideChangeStart',  () => {
					_current.innerHTML = (top_swiper.activeIndex + 1) ;
				})

			var thisTrigger = domselect('.swiper-button'),
				mainSwiper = domselect('.swiper-container._main'),
				gallerySwiper = domselect('.swiper-container.gallery-thumbs');
			thisTrigger.addEventListener('mouseenter', function(){
				mainSwiper.classList.add('_on')
				gallerySwiper.classList.add('_on')
				
			})
			gallerySwiper.addEventListener('mouseleave', function(){
				this.classList.remove('_on')
				mainSwiper.classList.remove('_on')
			})
		}
		if(domselect('a.cancel')){
	        domselect('a.cancel').addEventListener('click', function(e){
	        	e.preventDefault();
	        })
		}
		if(domselect('.my-gallery')){
			var initPhotoSwipeFromDOM = function(gallerySelector) {

			    // parse slide data (url, title, size ...) from DOM elements 
			    // (children of gallerySelector)
			    var parseThumbnailElements = function(el) {
			        var thumbElements = el.childNodes,
			            numNodes = thumbElements.length,
			            items = [],
			            figureEl,
			            linkEl,
			            size,
			            item;

			        for(var i = 0; i < numNodes; i++) {

			            figureEl = thumbElements[i]; // <figure> element

			            // include only element nodes 
			            if(figureEl.nodeType !== 1) {
			                continue;
			            }

			            linkEl = figureEl.children[0]; // <a> element

			            size = linkEl.getAttribute('data-size').split('x');

			            // create slide object
			            item = {
			                src: linkEl.getAttribute('href'),
			                w: parseInt(size[0], 10),
			                h: parseInt(size[1], 10)
			            };



			            if(figureEl.children.length > 1) {
			                // <figcaption> content
			                item.title = figureEl.children[1].innerHTML; 
			            }

			            if(linkEl.children.length > 0) {
			                // <img> thumbnail element, retrieving thumbnail url
			                item.msrc = linkEl.children[0].getAttribute('src');
			            } 

			            item.el = figureEl; // save link to element for getThumbBoundsFn
			            items.push(item);
			        }

			        return items;
			    };

			    // find nearest parent element
			    var closest = function closest(el, fn) {
			        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
			    };

			    // triggers when user clicks on thumbnail
			    var onThumbnailsClick = function(e) {
			        e = e || window.event;
			        e.preventDefault ? e.preventDefault() : e.returnValue = false;

			        var eTarget = e.target || e.srcElement;

			        // find root element of slide
			        var clickedListItem = closest(eTarget, function(el) {
			            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
			        });

			        if(!clickedListItem) {
			            return;
			        }

			        // find index of clicked item by looping through all child nodes
			        // alternatively, you may define index via data- attribute
			        var clickedGallery = clickedListItem.parentNode,
			            childNodes = clickedListItem.parentNode.childNodes,
			            numChildNodes = childNodes.length,
			            nodeIndex = 0,
			            index;

			        for (var i = 0; i < numChildNodes; i++) {
			            if(childNodes[i].nodeType !== 1) { 
			                continue; 
			            }

			            if(childNodes[i] === clickedListItem) {
			                index = nodeIndex;
			                break;
			            }
			            nodeIndex++;
			        }



			        if(index >= 0) {
			            // open PhotoSwipe if valid index found
			            openPhotoSwipe( index, clickedGallery );
			        }
			        return false;
			    };

			    // parse picture index and gallery index from URL (#&pid=1&gid=2)
			    var photoswipeParseHash = function() {
			        var hash = window.location.hash.substring(1),
			        params = {};

			        if(hash.length < 5) {
			            return params;
			        }

			        var vars = hash.split('&');
			        for (var i = 0; i < vars.length; i++) {
			            if(!vars[i]) {
			                continue;
			            }
			            var pair = vars[i].split('=');  
			            if(pair.length < 2) {
			                continue;
			            }           
			            params[pair[0]] = pair[1];
			        }

			        if(params.gid) {
			            params.gid = parseInt(params.gid, 10);
			        }

			        return params;
			    };

			    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
			        var pswpElement = document.querySelectorAll('.pswp')[0],
			            gallery,
			            options,
			            items;

			        items = parseThumbnailElements(galleryElement);

			        // define options (if needed)
			        options = {

			            // define gallery index (for URL)
			            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

			            getThumbBoundsFn: function(index) {
			                // See Options -> getThumbBoundsFn section of documentation for more info
			                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
			                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
			                    rect = thumbnail.getBoundingClientRect(); 

			                return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
			            }

			        };

			        // PhotoSwipe opened from URL
			        if(fromURL) {
			            if(options.galleryPIDs) {
			                // parse real index when custom PIDs are used 
			                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
			                for(var j = 0; j < items.length; j++) {
			                    if(items[j].pid == index) {
			                        options.index = j;
			                        break;
			                    }
			                }
			            } else {
			                // in URL indexes start from 1
			                options.index = parseInt(index, 10) - 1;
			            }
			        } else {
			            options.index = parseInt(index, 10);
			        }

			        // exit if index not found
			        if( isNaN(options.index) ) {
			            return;
			        }

			        if(disableAnimation) {
			            options.showAnimationDuration = 0;
			        }

			        // Pass data to PhotoSwipe and initialize it
			        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
			        gallery.init();
			    };

			    // loop through all gallery elements and bind events
			    var galleryElements = document.querySelectorAll( gallerySelector );

			    for(var i = 0, l = galleryElements.length; i < l; i++) {
			        galleryElements[i].setAttribute('data-pswp-uid', i+1);
			        galleryElements[i].onclick = onThumbnailsClick;
			    }

			    // Parse URL and open gallery if it contains #&pid=3&gid=1
			    var hashData = photoswipeParseHash();
			    if(hashData.pid && hashData.gid) {
			        openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
			    }
			};

			// execute above function
			initPhotoSwipeFromDOM('.my-gallery');
		}
		if(s === "shop" && window.innerWidth <= 1025){
			var shop_swiper = new Swiper('.swiper-container._shop', {
				slidesPerView: 'auto',
				centeredSlides : true,
				mousewheelControl: false,
				direction: 'horizontal',
				pagination: '.swiper-pagination',
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				loop: false,
				speed: 900,
				parallax: true,
				paginationClickable: true,
				keyboardControl: true,
				a11y: true
	        });

		}
		

		if(domselect('#instafeed')){
			//var loadButton = document.getElementById('load-more');
			var userFeed = new Instafeed({
		        get: 'user',
		        userId: '194847780',
		        resolution: 'standard_resolution',
		        sortBy: 'random',
		        limit: 3,
		        accessToken: '194847780.1677ed0.2b1e727bde3c403284f4a6ef87dd5864',
		        template: '<div class="column col-4 _default">'
		        				+'<div class="_block _social _1x1">'
				                +'<a href="{{link}}" class="no-route" target="_blank">'
				                    +'<div class="image" style="background: url({{image}}); background-repeat: no-repeat; background-size:cover">'
				                    	+'<div class="_inner _noBg">'
				                    		+'<span class="likes">{{likes}}</span><span class="comments">{{comments}}</span>'
                						+'</div>'
				                    +'</div>'
				                +'</a>'
				                +'</div>'
			                +'</div>',

			    after: function(image) {

			    	window.requestAnimFrame = (function(){
				  	return  window.requestAnimationFrame   ||
						window.webkitRequestAnimationFrame ||
						window.mozRequestAnimationFrame    ||
						window.oRequestAnimationFrame      ||
						window.msRequestAnimationFrame     ||
						function(/* function */ callback, /* DOMElement */ element){
						window.setTimeout(callback, 1000 / 60);
						};
				})();

				var Q = {},
				    scrollTarget = 0 ;

				var container = domselect('.page'),
					_cover = domselect.all('.cover'),
					_blocks = document.querySelectorAll('.vsDiv'), i,
					_columns = domselect.all('._offset'),
				    bottom = - (container.scrollHeight - container.scrollTop) + (window.innerHeight + 22);
				    
					Q.Y = 0;

					function render() {
				            
			            //console.log(Q.Y)

			            if( bottom > Q.Y){ Q.Y = bottom }
			            if (Q.Y > 0) { Q.Y = 0 }

			            if(scrollTarget > 50){
			                TweenLite.to('header', 1, {opacity: 0})
			            }else{
			                TweenLite.to('header', 1, {opacity: 1})
			            }
			            if(scrollTarget < -50){
			                TweenLite.to('header', 1, {opacity: 0})
			            }else{
			                TweenLite.to('header', 1, {opacity: 1})
			            }

			     		var nav = domselect('header nav'),
			     			footer = domselect('.footer');


			            TweenMax.to(_blocks, 0.25, {
			                y: Q.Y ,
			                ease: Expo.easeOut,
			                overwrite: 'auto'
			            });
			            [].forEach.call(_cover, function(els){

				            TweenMax.to(els, 0.5, {
				                y: - Q.Y/2,
				                ease: Expo.easeOut,
				                overwrite: 'auto'
				            });
			            })
			            //if(_columns){
							for (var i = 0; i < _columns.length; i++) {
					            TweenMax.to(_columns[i], 0.25 + ((i + 1) * 0.25), {
					                y: Q.Y,
					                ease: Expo.easeOut,
					                overwrite: 'auto'
					            });
					        }
					    //}

			        }

			        if (window.innerWidth < 1024) {
						container.addEventListener('scroll', function (event) {
					    	event.preventDefault()
					    	scrollTarget = container.pageXOffset || container.scrollTop
					  	})
					} else {
						window.addEventListener('wheel', function (event) {
					    	event.preventDefault()
					    	Q.Y -= event.deltaY
					  })
					}
					function update(){
					    render();
					    window.requestAnimFrame(update);
					}

					update();
			  
				}
			});
			userFeed.run();
		}

		


	}

	animateOut(req, done) {
		
		//classes.remove(config.body, `is-${this.slug}`)

		// var pager = document.querySelector('.pager'),
		// 	pagerText = document.querySelector('.pager span p');
		// 	pagerText.innerHTML = 's magazine';
		// 	pager.classList.remove('_hidden')
			
			

		// 	setTimeout(function(){
		// 		pager.classList.remove('_bottom')
		// 	}, 500)
		
		TweenLite.to(this.page, .7, {
			autoAlpha: 0,
			opacity:0,
			ease: Expo.easeInOut,
			onComplete: done
		})
	}

	destroy(req, done) {
		var tile = document.querySelector('.meta-tile'),
		currentPage = document.querySelector('.js-current-page'),
	    s = tile.dataset.slug;

	   	currentPage.innerHTML = ''
	    classes.remove(config.body, 'is-'+s)
	    classes.remove(this.page, 'page-'+s)


		super.destroy()

		this.ui = null

		this.page.parentNode.removeChild(this.page)
		
		done()
	}
}

module.exports = Chanels