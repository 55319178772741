import framework from 'framework'
import config from 'config'
import utils from 'utils'
import domselect from 'dom-select'
import event from 'dom-event'
import classes from 'dom-classes'
import query from 'query-dom-components'
import biggie from '@utils/biggie'

class Default {
    
    constructor(opt = {}) {
          
        this.view = config.view
        this.page = null
        this.a = null
    }
    
    init(req, done, options) {

        const opts = options || { cache: true, sub: false }
        
        const view = this.view
        const ready = this.ready.bind(this, done)
        const page = this.page = biggie.page(req, view, opts, ready)
    }

    ready() {

        this.ui = query({ el: this.page })
        
        this.a = domselect.all('a', this.page)
        
        biggie.bind.add(this.a)

         //custom routing
        var arrayOfElements = document.querySelectorAll('.nav-item');
        biggie.bind.add(arrayOfElements);

        //Dynamic Title
        var tile = document.querySelectorAll('.meta-tile'),
            page = document.querySelector('.page');
        [].forEach.call(tile, function(el){
            var t = el.dataset.title
            var s = el.dataset.slug
            var titleTag = document.querySelector('title')
            titleTag.innerHTML = t
            document.body.classList.add('is-'+s)
            page.classList.add('page-'+s)
        });

        var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
           navigator.userAgent && !navigator.userAgent.match('CriOS');
           if(isSafari){
                document.body.classList.add('is-safari');
           }

        var host = new RegExp('/' + window.location.host + '/');
        var links = document.querySelectorAll('.innerTxt a');
        [].forEach.call(links, function(link) {
          if (!link.href.match(host)) {
            link.setAttribute('target','_blank');
          }
          if(window.innerWidth <= 1024){
            function openTab(url) {
                // Create link in memory
                var a = window.document.createElement("a");
                  a.target = '_blank';
                  a.href = url;
               
                  // Dispatch fake click
                  var e = window.document.createEvent("MouseEvents");
                  e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                  a.dispatchEvent(e);

                  g();
              };
            function g(){
              setTimeout(o, 1);
            }
            function o(){
              window.open(link.href);
              openTab(link.href);
            }
            
          }else{
            var url = link.getAttribute('href')
            link.target = '_blank';
            //console.log(url);
            window.open = url ;
          }
        });
    }
    
    resize(width, height) {
        
        config.height = height
        config.width = width
    }
    
    destroy() {

        biggie.bind.remove(this.a)

          var tile = document.querySelectorAll('.meta-tile'),
            page = document.querySelector('.page');
        [].forEach.call(tile, function(el){
            var s = el.dataset.slug
            document.body.classList.remove('is-'+s)
            page.classList.remove('page-'+s)
        })

        this.a = null
    }
}

module.exports = Default